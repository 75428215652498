import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import { Verify } from '../../utils/Verify';
import { EmployeeGridController } from '../controller/EmployeeGridController';
import { Utils } from '../../resources/Utils';

class EmployeeEditFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.employeeGridController = new EmployeeGridController();
		this.verify = new Verify();
		this.state = {
			view: 0,
			forme: {
				userVO: {
					email: '',
					otherIdentifier: '',
					idPerson: {
						name: '',
						surname: '',
						phone: 0,
						birthdate: ''
					}
				}
			}
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
		this.actualForm();

		if (!this.verify.securityVerify('admin')) {
			this.props.history.push('/superAdminMenu');
		}
	}

	handleChange = (e) => {
		this.setState({
			forme: {
				...this.state.forme,
				userVO: {
					...this.state.forme.userVO,
					idPerson: {
						...this.state.forme.userVO.idPerson,
						[e.target.name]:
							e.target.name === 'phone'
								? e.target.value.replace(/\D/g, '')
								: e.target.value
					}
				}
			}
		});
	};

	handleChangeEmail = (e) => {
		e.target.value.toUpperCase();
		this.setState({
			forme: {
				...this.state.forme,
				userVO: {
					...this.state.forme.userVO,
					[e.target.name]: e.target.value
				}
			}
		});
	};

	back = (_) => {
		if (this.state.view === 0) {
			window.location.reload(true);
		} else {
			window.history.back();
		}
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	actualForm() {
		if (this.props.form) {
			this.setState({ view: 0 });
			this.setState({ forme: this.props.form });
		} else {
			this.setState({ view: 1 });
		}
	}

	maxDate() {
		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth() + 1; // January is 0!
		const yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		today = yyyy + '-' + mm + '-' + dd;
		return today;
	}

	edit = async (event) => {
		event.preventDefault();
		if (this.state.view === 0) {
			const response = await this.employeeGridController.edit(this.state.forme);

			if (response.indexOf('success') !== -1) {
				Utils.swalSuccess('Se editó al usuario correctamente!');
				window.location.reload(true);
			} else if (response.includes('telefono')) {
				Utils.swalError(
					'El telefono que intenta ingresar ya fue usado por otro usuario...'
				);
			} else if (response.includes('usuario')) {
				Utils.swalError(
					'El correo que intenta ingresar ya fue usado por otro usuario...'
				);
			} else {
				Utils.swalError('Ocurrio un error al editar el usuario');
				window.location.reload(true);
			}
		}
	};

	insert = async (event) => {
		event.preventDefault();
		const response = await this.employeeGridController.insert(this.state.forme);
		if (response.indexOf('success') !== -1) {
			Utils.swalSuccess('Se insertó al nuevo usuario correctamente!');
			this.props.history.push('/usersGrid');
		} else if (response.includes('telefono')) {
			Utils.swalError(
				'El telefono que intenta ingresar ya fue usado por otro usuario...'
			);
		} else if (response.includes('email')) {
			Utils.swalError(
				'El correo que intenta ingresar ya fue usado por otro usuario...'
			);
		} else if (response.includes('identifier')) {
			Utils.swalError(
				'El identificador que intenta ingresar ya fue usado por otro usuario...'
			);
		} else {
			Utils.swalError('Ocurrio un error al ingresar el usuario');
			this.props.history.push('/usersGrid');
		}
	};

	parseDate = (date) => {
		const cadena =
			date.substring(6, 10) +
			'-' +
			date.substring(3, 5) +
			'-' +
			date.substring(0, 2);
		return cadena;
	};

	tittleS() {
		let res;
		if (this.props.form) {
			sessionStorage.getItem('typeUser') &&
			sessionStorage.getItem('typeUser') === 'admin'
				? (res = <h4 className='text-center'> Edición de Administrador </h4>)
				: (res = <h4 className='text-center'> Edición de Empleado </h4>);
		} else {
			sessionStorage.getItem('typeUser') &&
			sessionStorage.getItem('typeUser') === 'admin'
				? (res = <h4 className='text-center'> Alta de Administrador </h4>)
				: (res = <h4 className='text-center'> Alta de Empleado </h4>);
		}
		return res;
	}

	formS() {
		if (this.props.form) {
			return (
				<form onSubmit={this.edit} encType='multipart/form-data'>
					{this.renderForm()}
				</form>
			);
		} else {
			return (
				<form onSubmit={this.insert} encType='multipart/form-data'>
					{this.renderForm()}
				</form>
			);
		}
	}

	buttonS() {
		if (this.props.form) {
			return (
				<input
					className='btn btn-success btn-block'
					type='submit'
					value={
						sessionStorage.getItem('typeUser') === 'admin'
							? 'Editar administrador'
							: 'Editar empleado'
					}
				/>
			);
		} else {
			return (
				<input
					className='btn btn-success btn-block'
					type='submit'
					value={
						sessionStorage.getItem('typeUser') === 'admin'
							? 'Registrar administrador'
							: 'Registrar empleado'
					}
				/>
			);
		}
	}

	renderForm() {
		return (
			<table className='table table-dark table-striped'>
				<tbody>
					<tr>
						<td>
							<label>Nombre:</label>
							<input
								className='form-control'
								type='text'
								pattern='^[A-ZÁÉÍÓÚ][a-zñáéíóú]+(?: [A-ZÁÉÍÓÚ][a-zñáéíóú]+)?$'
								maxLength={45}
								placeholder={this.state.forme.userVO.idPerson.name}
								value={this.state.forme.userVO.idPerson.name}
								name='name'
								onChange={this.handleChange}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Apellido:</label>
							<input
								className='form-control'
								type='text'
								name='surname'
								pattern='^[A-ZÁÉÍÓÚ][a-zñáéíóú]+(?: [A-ZÁÉÍÓÚ][a-zñáéíóú]+)?$'
								maxLength={45}
								onChange={this.handleChange}
								placeholder={this.state.forme.userVO.idPerson.surname}
								value={this.state.forme.userVO.idPerson.surname}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Fecha de Nacimiento:</label>
							<input
								className='form-control'
								type='date'
								name='birthdate'
								onChange={this.handleChange}
								max={Utils.maxFech()}
								min={Utils.minFech()}
								data-date-format='yyyy-MM-dd'
								value={this.state.forme.userVO.idPerson.birthdate}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Correo:</label>
							<input
								className='form-control'
								type='email'
								name='email'
								maxLength={45}
								placeholder='ejemplo@dominio.com.mx'
								onChange={this.handleChangeEmail}
								value={this.state.forme.userVO.email}
								pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$'
								title='Letras a-z, números 0-9 y simbolos ._%+- "ejemplo@dominio.gov"'
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Identificador:</label>
							<input
								className='form-control'
								type='text'
								maxLength={20}
								placeholder='clave de usuario'
								value={this.state.forme.userVO.otherIdentifier}
								name='otherIdentifier'
								onChange={this.handleChangeEmail}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Teléfono:</label>
							<input
								className='form-control'
								type='tel'
								name='phone'
								min='1000000000'
								pattern='[1-9][0-9]{9}'
								maxLength='10'
								onChange={this.handleChange}
								value={this.state.forme.userVO.idPerson.phone}
								title='Proporcione solo 10 dígitos en números del 0 al 9 y que el número no comience con 0'
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div>{this.buttonS()}</div>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	render() {
		return (
			<div className='container h-100 py-md-4 py-lg-4 py-sm-2 py-xl-5'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-8 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<div className='text-center'>{this.tittleS()}</div>
							</div>

							<div className='card-body'>
								<div className='container'>
									<div className='table-responsive'>
										<div className='table-responsive'>{this.formS()}</div>
									</div>
								</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(EmployeeEditFormComponent);
