import React from 'react';
import { Utils } from '../../resources/Utils';
import { withRouter } from 'react-router-dom';
import { Verify } from '../../utils/Verify';
import {
	Add,
	ArrowBackIos,
	ArrowForward,
	ArrowLeft,
	ArrowRight,
	Edit,
	Home,
	ToggleOff,
	ToggleOn
} from '@material-ui/icons';
import BusinessSelector from '../../resources/BusinessSelector';
import { EmployeeGridController } from '../controller/EmployeeGridController.js';
import EmployeeEditFormComponent from '../component/EmployeeEditFormComponent';
import { Preload } from '../../utils/Preload';

class EmployeeGridComponent extends React.Component {
	constructor(props) {
		super(props);
		this.employeeGridController = new EmployeeGridController();
		this.verify = new Verify();
		this.utils = new Utils();
		this.state = {
			tableData: {
				page: 0,
				limit: 8
			},
			page: 1,
			cardValue: 0,
			data: {
				data: [],
				total: 0
			}
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
		if (!this.verify.securityVerify('admin')) {
			this.props.history.push('/superAdminMenu');
		}
	}

	async loadData() {
		return await this.employeeGridController.init(this.state.tableData);
	}

	loaderFn = async () => {
		return await this.loadData().then((body) => this.setState({ data: body }));
	};

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	getNext = async (event) => {
		event.preventDefault();
		this.setState(
			(prevState) => ({
				tableData: {
					...prevState.tableData,
					page: prevState.tableData.page + 1
				},
				page: prevState.page + 1,
				data: { ...prevState.data, data: [] }
			}),
			async () => {
				const body = await this.employeeGridController.init(
					this.state.tableData
				);
				this.setState({ data: body });
			}
		);
	};

	getPrev = async (event) => {
		event.preventDefault();
		if (this.state.tableData.page !== 0) {
			this.setState(
				(prevState) => ({
					tableData: {
						...prevState.tableData,
						page: prevState.tableData.page - 1
					},
					page: prevState.page - 1,
					data: { ...prevState.data, data: [] }
				}),
				async () => {
					const body = await this.employeeGridController.init(
						this.state.tableData
					);
					this.setState({ data: body });
				}
			);
		} else {
			Utils.swalSuccess('No se puede obtener datos previos');
		}
	};

	changeStateFinal = (data) => {
		this.setState({ cardValue: data.id, form: data });
	};

	isValid = (date) => {
		const fechaActual = new Date();
		fechaActual.setHours(0, 0, 0, 0);
		const fechaBD = new Date(date);
		fechaBD.setHours(0, 0, 0, 0);
		fechaBD.setDate(fechaBD.getDate() + 1);
		if (fechaBD.getDate()) {
			return (
				<button type='button' className='btn btn-danger disabled'></button>
			);
		} else {
			return (
				<button type='button' className='btn btn-success disabled'></button>
			);
		}
	};

	refreshButton() {
		return (
			<div className='col-md-6 pl-1 pr-4'>
				<a href='/usersGrid' className='btn btn-primary pl-6'>
					<ArrowForward />
				</a>
			</div>
		);
	}

	onOff = async (id, bus, name, option) => {
		const tipe =
			sessionStorage.getItem('typeUser') === 'admin'
				? 'Administrador'
				: 'Empleado';
		const user = sessionStorage.getItem('typeUser');

		if (option === 1) {
			Utils.swl({
				title: '¿Desea Activar el ' + tipe + ' ' + name + ' ?',
				showCancelButton: true,
				confirmButtonText: `Activar`,
				cancelButtonText: `Cancelar`
			}).then((result) => {
				if (result.isConfirmed) {
					this.statusEmployee(id, bus, user, 1);
				}
			});
		} else {
			Utils.swl({
				title: '¿Desea Activar el ' + tipe + ' ' + name + ' ?',
				showCancelButton: true,
				confirmButtonText: `Desactivar`,
				cancelButtonText: `Cancelar`
			}).then((result) => {
				if (result.isConfirmed) {
					this.statusEmployee(id, bus, user, 0);
				}
			});
		}
	};

	statusEmployee = async (id, bus, tipeUser, estatus) => {
		await this.employeeGridController.activeUnActiveEmployee(
			id,
			bus,
			tipeUser,
			estatus
		);
		this.reload();
	};

	reload() {
		window.location.reload(false);
	}

	renderBody() {
		return this.state.data.total !== 0 ? (
			this.state.data.data.map((d) => (
				<tr key={d.userVO.id}>
					<td>{d.userVO.idPerson.name}</td>
					<td>{d.userVO.idPerson.surname}</td>
					<td>{d.userVO.email}</td>
					<td>{d.userVO.otherIdentifier}</td>
					<td>{d.userVO.idPerson.phone}</td>
					{sessionStorage.getItem('role') === 'employe' ? null : (
						<td>
							<Edit onClick={() => this.changeStateFinal(d)}> Editar</Edit>
						</td>
					)}
					<td>
						{sessionStorage.getItem('role') === 'admin' &&
						sessionStorage.getItem('typeUser') === 'admin' ? (
							<></>
						) : d.status === 0 ? (
							<ToggleOff
								onClick={() =>
									this.onOff(
										d.userVO.id,
										d.businessVO.id,
										d.userVO.idPerson.name + ' ' + d.userVO.idPerson.surname,
										1
									)
								}
							>
								Activo
							</ToggleOff>
						) : (
							<ToggleOn
								color='primary'
								onClick={() =>
									this.onOff(
										d.userVO.id,
										d.businessVO.id,
										d.userVO.idPerson.name + ' ' + d.userVO.idPerson.surname,
										0
									)
								}
							>
								{' '}
								Inactivo{' '}
							</ToggleOn>
						)}
					</td>
				</tr>
			))
		) : (
			<tr key={1}>
				{' '}
				<td colSpan={7}>
					<h4 className='text-center font-weight-bold'>Sin datos</h4>
				</td>{' '}
			</tr>
		);
	}

	parseDate = (date) => {
		const cadena =
			date.substring(8, 10) +
			'-' +
			date.substring(5, 7) +
			'-' +
			date.substring(0, 4);
		return cadena;
	};

	renderCard() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-12 align-self-center'>
						<div
							className='card shadow bg-dark text-white'
							style={{ minHeight: '75%' }}
						>
							<div className='card-header'>
								<h4 className='text-center'>
									{sessionStorage.getItem('typeUser') === 'employee'
										? 'Empleados'
										: 'Administradores'}
								</h4>
							</div>
							<div className='card-body'>
								{sessionStorage.getItem('role') !== 'sudosu' ? (
									<></>
								) : (
									<div className='d-flex row flex-row col-13'>
										<div className='col-12 col-lg-10 col-xl-10 col-md-10'>
											<BusinessSelector />
										</div>
										<div>{this.refreshButton()} </div>
										<div>
											<a href='/userForm' className='btn btn-success me-2'>
												<Add /> Agregar
											</a>
										</div>
									</div>
								)}
								<br />
								<div className='container-fluid'>
									<div className='row justify-content-center'>
										<div className='table-responsive'>
											<Preload
												type='load'
												style={{ height: 350 }}
												callback={this.renderBody}
												callToService={this.loaderFn}
											>
												<table className='table table-dark table-striped '>
													<thead className='thead-dark'>
														<tr>
															<th>Nombre</th>
															<th>Apellido</th>
															<th>Correo</th>
															<th>Identificador</th>
															<th>Teléfono</th>
															{sessionStorage.getItem('role') ===
															'employe' ? null : (
																<th>Editar</th>
															)}
															<th>Estado</th>
														</tr>
													</thead>
													<tbody>{this.renderBody()}</tbody>
												</table>
											</Preload>
										</div>
										<div className='container-fluid'>
											<div className='row'>
												<div className='col-9 align-content-end'>
													Página: {this.state.page} de{' '}
													{Math.ceil(this.state.data.total / 8)}
												</div>
												<div className='col-1'>
													{this.state.tableData.page === 0 ? (
														<></>
													) : (
														<ArrowLeft onClick={this.getPrev} />
													)}
												</div>
												<div className='col-1'>
													{this.state.data.total >
													this.state.tableData.page * 8 + 8 ? (
														<ArrowRight onClick={this.getNext} />
													) : (
														<></>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				{this.state.cardValue < 1 ? (
					<div>{this.renderCard()}</div>
				) : (
					<div>{<EmployeeEditFormComponent form={this.state.form} />}</div>
				)}
			</>
		);
	}
}
export default withRouter(EmployeeGridComponent);
