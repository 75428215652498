import React from 'react';
import { isInvalidBtnExpress, Utils } from '../../resources/Utils';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { CuponGridController } from '../controller/CuponGridController';
import { withRouter } from 'react-router-dom';
import CuponEditFormComponent from '../../CuponForm/component/CuponEditFormComponent';
import {
	Check,
	Delete,
	Edit,
	Home,
	ArrowLeft,
	ArrowRight,
	ToggleOn,
	ToggleOff,
	ArrowForward,
	Block,
	CheckCircle
} from '@material-ui/icons';
import { Verify } from '../../utils/Verify';
import BusinessSelector from '../../resources/BusinessSelector';
import { Preload } from '../../utils/Preload';

class CuponGridComponent extends React.Component {
	constructor(props) {
		super(props);
		this.cuponGridController = new CuponGridController();
		this.verify = new Verify();
		this.utils = new Utils();
		this.state = {
			tableData: {
				page: 0,
				limit: 8
			},
			page: 1,
			cardValue: 0,
			data: {
				data: [],
				total: 0
			}
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
	}

	async loadData() {
		return await this.cuponGridController.init(this.state.tableData);
	}

	loaderFn = async () => {
		return await this.loadData().then((value) => {
			this.setState({ data: value }, () => this.rewriteDates());
		});
	};

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	getNext = async (event) => {
		event.preventDefault();
		await this.setState((prevState) => ({
			page: this.state.page + 1,
			tableData: {
				...prevState.tableData,
				page: prevState.tableData.page + 1
			}
		}));
		const body = await this.cuponGridController.init(this.state.tableData);
		this.setState({ data: body }, () => this.rewriteDates());
	};

	getPrev = async (event) => {
		event.preventDefault();
		if (this.state.tableData.page !== 0) {
			await this.setState((prevState) => ({
				page: this.state.page - 1,
				tableData: {
					...prevState.tableData,
					page: prevState.tableData.page - 1
				}
			}));
		} else {
			Utils.swalSuccess('No se puede obtener datos previos.');
		}
		const body = await this.cuponGridController.init(this.state.tableData);
		this.setState({ data: body }, () => this.rewriteDates());
	};

	changeStateFinal = (data) => {
		this.setState({ cardValue: data.id, form: data });
	};

	rewriteDates() {
		const dates = [];
		for (let i = 0; i < this.state.data.data.length; i++) {
			const aux = this.state.data.data[i];
			aux.expirationDate = this.parseDate(
				this.state.data.data[i].expirationDate
			);
			dates.push(aux);
		}
		this.setState({
			data: {
				...this.state.data,
				data: dates
			}
		});
	}

	createSon = async (id) => {
		await this.cuponGridController.createSon(id);
		this.props.history.push('/clientAdd');
	};

	renderBody() {
		return this.state.data.data.map((d) => (
			<tr key={d.id}>
				<td>{d.description}</td>
				<td>{d.discount}</td>
				<td>{d.isIncrement === 1 ? <CheckCircle /> : <Block />}</td>
				<td
					data-toggle='tooltip'
					data-placement='right'
					title={'Escaneos restantes: ' + d.lifes}
				>
					{d.lifes}
				</td>
				<td>
					{d.expirationDate}{' '}
					{isInvalidBtnExpress(new Date(), d.expirationDate) === false ? (
						<button type='button' className='btn btn-danger disabled'></button>
					) : (
						<button type='button' className='btn btn-success disabled'></button>
					)}
				</td>
				<td>
					{isInvalidBtnExpress(new Date(), d.expirationDate) === false ? (
						<button
							className='btn btn-primary p-1'
							onClick={() => this.createSon(d.id)}
						>
							Express
						</button>
					) : (
						<></>
					)}
				</td>
				{sessionStorage.getItem('role') === 'employee' ? (
					<></>
				) : (
					<td>
						<Edit onClick={() => this.changeStateFinal(d)}> Editar</Edit>
					</td>
				)}
				{sessionStorage.getItem('role') === 'employee' ? (
					<td>
						{d.status === 0 ? (
							<ToggleOff> Activar</ToggleOff>
						) : (
							<ToggleOn color='primary'> Desactivar</ToggleOn>
						)}
					</td>
				) : (
					<td>
						{d.status === 0 ? (
							<Check onClick={() => this.active(d.id, d.description)}>
								{' '}
								Activar
							</Check>
						) : (
							<Delete onClick={() => this.delete(d.id, d.description)}>
								{' '}
								Desactivar
							</Delete>
						)}
					</td>
				)}
			</tr>
		));
	}

	parseDate = (date) => {
		const fechaBD = new Date(date);
		fechaBD.setHours(0, 0, 0, 0);
		fechaBD.setDate(fechaBD.getDate() + 1);
		const day = fechaBD.getDate();
		const month = fechaBD.getMonth() + 1;
		const year = fechaBD.getFullYear();

		if (month < 10) {
			if (day < 10) return `0${day}-0${month}-${year}`;
			else return `${day}-0${month}-${year}`;
		} else {
			if (day < 10) return `0${day}-${month}-${year}`;
			else return `${day}-${month}-${year}`;
		}
	};

	renderCard() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-12 align-self-center'>
						<div
							className='card shadow bg-dark text-white'
							style={{ minHeight: '75%' }}
						>
							<div className='card-header'>
								<h4 className='text-center'>Cupones</h4>
							</div>
							<div className='card-body'>
								{sessionStorage.getItem('role') !== 'sudosu' ? (
									<></>
								) : (
									<div className='pt-4 pb-4 pl-2 pr-2'>
										<div>
											<BusinessSelector />
										</div>
										<div className='col-md-6 pl-2 pr-2'>
											<a href='/cuponGrid' className='btn btn-primary pl-6'>
												<ArrowForward />
											</a>
										</div>
									</div>
								)}
								<div className='container-fluid'>
									<div className='row justify-content-center'>
										{/* Se agrega la clase table-responsive para que se acomode la tabla */}
										<div className='table-responsive'>
											<Preload type='load' callToService={this.loaderFn}>
												<table className='table table-dark table-striped '>
													<thead className='thead-dark'>
														<tr>
															<th>Descripción</th>
															<th>Descuento</th>
															<th>Es incremental</th>
															<th
																data-toggle='tooltip'
																data-placement='right'
																title='Número de escaneos restantes de cada cupón.'
															>
																Vidas
															</th>
															<th>Fecha de Expiración</th>
															<th>Crear cupón único</th>
															{sessionStorage.getItem('role') === 'employee' ? (
																''
															) : (
																<th>Editar</th>
															)}
															<th>Estado</th>
														</tr>
													</thead>
													<tbody>{this.renderBody()}</tbody>
												</table>
											</Preload>
											<div className='container-fluid'>
												<div className='row'>
													<div className='col-9 align-content-end'>
														Página: {this.state.page} de{' '}
														{Math.ceil(this.state.data.total / 8)}
													</div>
													<div className='col-1'>
														{this.state.tableData.page === 0 ? (
															<></>
														) : (
															<ArrowLeft onClick={this.getPrev} />
														)}
													</div>
													<div className='col-1'>
														{this.state.data.total >
														this.state.tableData.page * 8 + 8 ? (
															<ArrowRight onClick={this.getNext} />
														) : (
															<></>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	delete = async (id, description) => {
		Utils.swl({
			title: "¿Desea desactivar este cupón?\n'" + description + "'",
			showCancelButton: true,
			confirmButtonText: `Desactivar`,
			cancelButtonText: `Cancelar`
		}).then((result) => {
			if (result.isConfirmed) {
				this.cuponGridController.delete(id).then(() => {
					this.loadData().then(() =>
						setTimeout(() => {
							window.location.reload();
						}, 1000)
					);
				});
			}
		});
	};

	active = async (id, description) => {
		Utils.swl({
			title: "¿Desea activar este cupón?\n'" + description + "'",
			showCancelButton: true,
			confirmButtonText: `Activar`,
			cancelButtonText: `Cancelar`
		}).then((result) => {
			if (result.isConfirmed) {
				this.cuponGridController.active(id).then(() => {
					this.loadData().then(() =>
						setTimeout(() => {
							window.location.reload();
						}, 1000)
					);
				});
			}
		});
	};

	render() {
		return (
			<>
				{this.state.cardValue < 1 ? (
					<div>{this.renderCard()}</div>
				) : (
					<div>
						<CuponEditFormComponent form={this.state.form} />
					</div>
				)}
			</>
		);
	}
}
export default withRouter(CuponGridComponent);
